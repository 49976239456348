import {
  createSlice,
  createAsyncThunk,
  AsyncThunkAction,
} from "@reduxjs/toolkit";
import Prefix from "../config/ApiPrefix";
import AXIOS from "../config/Axios";
import SecureStorage from "../config/SecureStorage";

export const updateAssisment = createAsyncThunk(
  "updateAssisment",
  async (data, thunkAPI) => {
    const config = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${data}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(
        `${Prefix.api}health-risk/health_assessment/`,
        config
      );
      return await response.json();
    } catch (error) {
      return error;
    }
  }
);

export const getEcgAvailable = createAsyncThunk(
  "getEcgAvailable",
  async (query: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/booking/v2/get-time-slot-list/${query ? query : ""}`,
        {
          headers: { key: "RxzgzsVWZ1BqUGdmwJRdh2I5Y2nCZ3Q" },
        }
      );

      return data;
    } catch (err: any) {
      let title = "";
      if (err.response?.data?.errors) {
        title = String(err.response.data.errors);
      } else if (err.response?.data?.message) {
        title = String(err.response.data.message);
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);

export const getPackagesData = createAsyncThunk(
  "getPackagesData",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/center-package-data/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getRadPackagesData = createAsyncThunk(
  "getRadPackagesData",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/iframe-partner-package-list/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getParams = createAsyncThunk("getParams", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `api/external/v2/package-parameter-data/${obj?.url ? obj?.url : ""}`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});

export const getSlots = createAsyncThunk("getSlots", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${
        obj.type === "rad"
          ? "api/external/v2/get-radiology-time-slots/"
          : "api/booking/v2/get-time-slot-list/"
      }${obj?.url ? obj?.url : ""}`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});

export const getSlotsPPMC = createAsyncThunk(
  "getSlotsPPMC",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/v1/ppmc/get-time-slot-list-ppmc-list/${obj?.url ? obj?.url : ""}`,
        {
          headers: { key: `${obj?.key}` },
        }
      );

      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);

export const getCentreByKey = createAsyncThunk(
  "getCentreByKey",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/center/v2/center3p/${key ? key : ""}/`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const postMobileForOtp = createAsyncThunk(
  "postMobileForOtp",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/notification/send-whatsapp-otp/?otp_type=sms`,
        body
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const postVerifyOtp = createAsyncThunk(
  "postVerifyOtp",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/notification/verify-whatsapp-otp/`,
        body
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const checkGeoFenchMapMyIndia = createAsyncThunk(
  "checkGeoFenchMapMyIndia",
  async (val: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia_2/?eloc=${val.eloc}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message:
            "The entered location is non-serviceable" || "Something went wrong",
          status: false,
          type: "info",
          responseData: err.response.data,
        },
      };
      console.log(err.response.data, "responseData");

      if (val?.noErr) {
        return err.response.data;
      } else {
        return data;
      }
    }
  }
);
export const getMapMyIndiaAccessToken = createAsyncThunk(
  "getMapMyIndiaAccessToken",
  async () => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/open-mapmyindia-auth-token/?token=b4485893643d8fdd10577e77cff3c860`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPartnerKey = createAsyncThunk(
  "getPartnerKey",
  async (cid: any) => {
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/partner/3p-key/${cid}/`);
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getLocationrRevGeoCode = createAsyncThunk(
  "getLocationrRevGeoCode",
  async (val: any) => {
    try {
      const { data } = await AXIOS.get(
        `https://apis.mapmyindia.com/advancedmaps/v1/${val?.token}/rev_geocode?&lng=${val?.lng}&lat=${val?.lat}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const createBooking = createAsyncThunk(
  "createBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v2/center-create-booking/${
          body.url ? body.url : ""
        }`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const createBookingRad = createAsyncThunk(
  "createBookingRad",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `api/external/v2/iframe-imaging-booking/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const uploadPrescription = createAsyncThunk(
  "uploadPrescription",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v1/prescription-upload-lead/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getValidLocation = createAsyncThunk(
  "getValidLocation",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/center/v2/is-location-serviceable/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message:
            "The entered location is non-serviceable. Our backend team will connect with you." ||
            "Something went wrong",
          status: true,
          type: "error",
        },
      };
      if (obj?.noErr) {
        return err.response.data;
      } else {
        return data;
      }
    }
  }
);
export const getZone = createAsyncThunk("getZone", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api1}/phlebo/v2/get-zone/${obj?.url ? obj?.url : ""}`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message:
          "The entered location is non-serviceable. Our backend team will connect with you." ||
          "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getCouponCalc = createAsyncThunk(
  "getCouponCalc",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/get_prices/${obj?.url ? obj?.url : ""}`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getCoupon = createAsyncThunk("getCoupon", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/coupon/package-coupon/${obj?.url ? obj?.url : ""}`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getBooking = createAsyncThunk("getBooking", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api1}/external/v2/center-get-booking/${
        obj?.url ? obj?.url : ""
      }`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const updateBooking = createAsyncThunk(
  "updateBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v2/center-update-booking/${
          body?.force === true ? "?force=true" : ""
        }`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const resetState = createAsyncThunk("resetState", async () => {
  try {
    return null;
  } catch (err: any) {
    return null;
  }
});
export const createLead = createAsyncThunk("createLead", async (body: any) => {
  try {
    const { data2 } = await AXIOS.post(
      `${Prefix.api1}/lead/v2/lead/`,
      body.data,
      {
        headers: { key: `${body?.key}` },
      }
    );
    const data: any = {
      snack: {
        message: `We have registered you for home collection service. Our support team will connect with you shortly.`,
        status: true,
        type: "info",
      },
    };
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.error).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});


export const captureLeadDispostion = createAsyncThunk("captureLeadDispostion", async (body: any) => {
  try {
    const data2 = await AXIOS.post(
      `${Prefix.api1}/v1/lead/bulk-non-uniquecustomer-create/`,
      body.data,
      {
        headers: { key: `${body?.key}` },
      }
    );
    return data2;
  } catch (err: any) {
    let title = "";
    if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.error).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});

export const updateLead = createAsyncThunk("updateLead", async (body: any) => {
  try {
    const { data2 } = await AXIOS.put(
      `${Prefix.api1}/lead/v2/lead/${body.id}/`,
      body.data,
      {
        headers: { key: `${body?.key}` },
      }
    );
    const data: any = {
      snack: {
        message:
          `We have updated you for home collection service. Our support team will connect with you shortly.` ||
          "Something went wrong",
        status: true,
        type: "error",
      },
    };

    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.error).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const submitReview = createAsyncThunk(
  "submitReview",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/booking-feedback-create/?key=${body.key}`,
        body.data
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateBookingEmail = createAsyncThunk(
  "updateBookingEmail",
  async (body: any) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/open-data/${body.id}/?key=${body.key}`,
        body.data
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateBookingCredit = createAsyncThunk(
  "updateBookingCredit",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v2/center-update-is-credit/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPrevBooking = createAsyncThunk(
  "getPrevBooking",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/center-get-booking/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPrevLead = createAsyncThunk("getPrevLead", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api1}/lead/v2/lead/${obj?.url ? obj?.url : ""}`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getEmailOtp = createAsyncThunk(
  "getEmailOtp",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/send-custom-iframe-otp/${key ? key : ""}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const postOtpForEmail = createAsyncThunk(
  "postOtpForEmail",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/validate-iframe-otp/${key ? key : ""}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.error) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getEmpDetails = createAsyncThunk(
  "getEmpDetails",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/validate-iframe-token/`,
        {
          headers: { key: key },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";

      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      if (title === "Authentication Token Expired! ") {
        SecureStorage.removeItem("tokenIframe");
        window.location.reload();
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const removeEmpToken = createAsyncThunk(
  "removeEmpToken",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/set-is-used/`,
        {
          headers: { key: key },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const sendPaymentId = createAsyncThunk(
  "sendPaymentId",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/payment/order_payment/${body?.url ? body.url : ""}`,
        body.data
        // {
        //   headers: { key: `${body?.key}` },
        // }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const sendConfirmBooking = createAsyncThunk(
  "sendConfirmBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `api/external/v2/center-confirm-booking/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getSubCenterInfo = createAsyncThunk(
  "getSubCenterInfo",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/external/v2/iframe-center-address/${obj?.url ? obj?.url : ""}`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getAliasCities = createAsyncThunk(
  "getAliasCities",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/external/v2/alias-city-v2/${obj?.url ? obj?.url : ""}`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
//reducers
const initialState = {
  data: {},
  loading: true,
  packageParams: [],
  radPackages: [],
  mobileForOtp: [],
  centreByKey: [],
  verifyOtp: [],
  geoFencesMapMyIndia: {},
  mapmyindiaAccessToken: {},
  partnerKey: {},
  slots: [],
  slotsPPMC: [],
  revGeoCode: [],
  bookingCreated: {},
  snackbarData: {},
  uploadPrescriptionData: {},
  validLocation: {},
  bookingData: {},
  prevBookingData: {},
  bookingUpdated: {},
  bookingUpdatedEmail: {},
  bookingUpdatedCredit: {},
  zone: {},
  couponCalc: {},
  coupon: {},
  emailOtp: {},
  emailOtpVerified: {},
  empDetails: {},
  ecgAvailableData: {},
  prevLeadData: {},
  subCenterData: {},
  aliasCities: {},
  params: {},
  captureUpdated:{}
};
const webAppSlice = createSlice({
  name: "webAppSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAssisment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateAssisment.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPackagesData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPackagesData.fulfilled, (state, action) => {
        state.packageParams = [];
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.packageParams = action.payload;
        }
        state.loading = false;
      })

      .addCase(getParams.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getParams.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.params = action.payload;
        }
        state.loading = false;
      })
      .addCase(getAliasCities.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.aliasCities = action.payload;
        }
        state.loading = false;
      })
      .addCase(getAliasCities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSubCenterInfo.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.subCenterData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getRadPackagesData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRadPackagesData.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.radPackages = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCoupon.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.couponCalc = { remove_coupon: true ,snakData:action.payload.snack };
          state.snackbarData = action.payload.snack;
        } else {
          state.coupon = action.payload;
        }
        state.loading = false;
      })
      .addCase(postMobileForOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.mobileForOtp = action.payload;
        }
      })
      .addCase(getCentreByKey.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCentreByKey.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.centreByKey = action.payload;
        }
        state.loading = false;
      })
      .addCase(postVerifyOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.verifyOtp = action.payload;
        }
      })
      .addCase(checkGeoFenchMapMyIndia.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.geoFencesMapMyIndia = {
            status: false,
            geoData: action?.payload?.snack?.responseData,
          };
        } else {
          state.geoFencesMapMyIndia = action.payload;
        }
      })
      .addCase(getMapMyIndiaAccessToken.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.mapmyindiaAccessToken = action.payload;
        }
      })
      .addCase(getPartnerKey.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.partnerKey = action.payload;
        }
      })
      .addCase(getSlots.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSlots.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.slots = action.payload;
        }
        state.loading = false;
      })
      .addCase(getSlotsPPMC.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSlotsPPMC.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.slotsPPMC = action.payload;
        }
        state.loading = false;
      })
      .addCase(getLocationrRevGeoCode.fulfilled, (state, action) => {
        state.revGeoCode = action.payload;
      })
      .addCase(createBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingCreated = action.payload;
        }
        state.loading = false;
      })
      .addCase(createBookingRad.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createBookingRad.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingCreated = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCouponCalc.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCouponCalc.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.couponCalc = { remove_coupon: true };
          state.snackbarData = action.payload.snack;
        } else {
          state.couponCalc = action.payload;
        }
        state.loading = false;
      })
      .addCase(uploadPrescription.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadPrescription.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.uploadPrescriptionData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getValidLocation.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.validLocation = { valid: false };
        } else {
          state.validLocation = { valid: true };
        }
        state.loading = false;
      })
      .addCase(getBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.bookingData = {};
        } else {
          state.bookingData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getPrevBooking.fulfilled, (state, action) => {
        state.prevBookingData = action.payload;
      })
      .addCase(getPrevLead.fulfilled, (state, action) => {
        state.prevLeadData = action.payload;
      })
      .addCase(updateBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingUpdated = action.payload;
        }
        state.loading = false;
      })
      .addCase(createLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingUpdated = action.payload;
        } 
        state.loading = false;
      })
      .addCase(captureLeadDispostion.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(captureLeadDispostion.fulfilled, (state, action) => {
        if (action?.payload?.snack) {
          state.snackbarData = action?.payload?.snack;
        } else {
          state.captureUpdated = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingUpdated = action.payload;
        }
        state.loading = false;
      })
      .addCase(resetState.fulfilled, (state, action) => {
        state.bookingUpdated = {};
        state.loading = false;
      })
      .addCase(getZone.fulfilled, (state, action) => {
        state.zone = action.payload;
      })
      .addCase(updateBookingEmail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateBookingEmail.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingUpdatedEmail = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateBookingCredit.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateBookingCredit.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingUpdatedCredit = action.payload;
        }
        state.loading = false;
      })
      .addCase(getEmailOtp.pending, (state, action) => {
        state.emailOtp = { status: false };
        state.loading = true;
      })
      .addCase(getEmailOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.emailOtp = { status: false };
        } else {
          state.emailOtp = action.payload;
        }
        state.loading = false;
      })
      .addCase(postOtpForEmail.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.emailOtpVerified = { errors: "yes" };
        } else {
          state.emailOtpVerified = action.payload;
        }
      })
      .addCase(getEmpDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEmpDetails.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.empDetails = action.payload.snack;
        } else {
          state.empDetails = action.payload;
        }
        state.loading = false;
      })
      .addCase(getEcgAvailable.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEcgAvailable.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.ecgAvailableData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getEcgAvailable.rejected, (state, action) => {
        state.loading = false;
        state.snackbarData = {
          message: action.error.message || "Something went wrong",
          status: true,
          type: "error",
        };
      });
  },
});

export default webAppSlice.reducer;
