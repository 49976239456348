import React, { useState } from "react";
import "./index.sass";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../storage/store";
import Loader from "../../components/Loader";
// import Loader from "../../../components/loader";
import ClearIcon from "@mui/icons-material/Clear";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Chip,
  Container,
  createStyles,
  Dialog,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  TableContainer,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import StyledButton from "../../components/Button/StyledButton";
import StyledButtonLght from "../../components/Button/StyledButtonLight";
import {
  getPackagesData,
  uploadPrescription,
  createLead,
  updateLead,
  getParams,
} from "../../services/actionSlice";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
interface Props {
  setOpenPackageModal: any;
  openPackageModal: any;
  updateMember: any;
  setCustomerPackages: any;
  addIndex: any;
  partnerKey: any;
  customerPackages: any;
  couponPackage: any;
  mainMember: any;
  customerDetails: any;
  setOpenConfirmedModal: any;
  setConfirmType: any;
  confirmType: any;
  loading: any;
  centreData: any;
  customerGender: any;
  empPackages: any;
  leadId: any;
  selectedDataPackages:any;
  isEcg: any;
}
const PackagePage: React.FC<Props> = ({
  setOpenPackageModal,
  openPackageModal,
  updateMember,
  setCustomerPackages,
  addIndex,
  partnerKey,
  customerPackages,
  couponPackage,
  mainMember,
  customerDetails,
  setOpenConfirmedModal,
  setConfirmType,
  selectedDataPackages,
  confirmType,
  loading,
  centreData,
  customerGender,
  empPackages,
  leadId,
  isEcg,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);
  const params = useSelector((state: any) => state.webAppSlice.params);

  const [selectedPackages, setSelectedPackages] = React.useState<any>(
    addIndex !== "" ? customerDetails[addIndex].packages : customerPackages
  );


  const [openPrescriptionModal, setOpenPrescriptionModal] =
    React.useState(false);

  const [listPackages, setListPackages] = useState<any>([]);
  const [listPackagesAdd, setListPackagesAdd] = useState<any>(customerDetails[addIndex]?.packages || []);
  const [searchInput, setSearchInput] = useState<any>("");
  const [view, setView] = useState<any>("");
  const [file, setFile] = useState<any>("");
  const [preview, setPreview] = useState<any>("");
  const [filter, setFilter] = useState<any>("");
  const [parameterLt, setParameterLt] = useState<any>(10000);
  const [parameterGt, setParameterGt] = useState<any>(0);
  const [priceGt, setPriceGt] = useState<any>(0);
  const [priceLt, setPriceLt] = useState<any>(100000);
  const [gender, setGender] = useState<any>(
    addIndex ? customerDetails[addIndex].customerGender : customerGender
  );
  const [type, setType] = useState<any>("");
  const [isSelected, setIsSelected] = useState<string>("");
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openParam, setOpenParam] = useState<boolean>(false);
  const [key, setKey] = useState<number>(0);
  const packages = useSelector((state: any) => state.webAppSlice.packageParams);
  const uploadPrescResp = useSelector(
    (state: any) => state.webAppSlice.uploadPrescriptionData
  );

  
  useEffect(() => {
    if (partnerKey?.center_key !== undefined) {
      dispatch(
        getPackagesData({
          url:
            gender !== "other"
              ? `?center_3p_config_id=${centreData.id}&recommended_gender=${gender}${selectedDataPackages ? "&paginated_by=200" : ""}`
              : `?center_3p_config_id=${centreData.id}`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [partnerKey, addIndex,selectedDataPackages]);

  useEffect(() => {
    if (partnerKey?.center_key !== undefined && filter) {
      dispatch(
        getPackagesData({
          url: `?center_3p_config_id=${centreData.id}&${filter}${selectedDataPackages ? "&paginated_by=200" : ""}`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [filter,selectedDataPackages]);

  useEffect(() => {
    if (packages && packages.data) {
      if (searchInput) {
        //@ts-ignore
        gtag("event", "add_filter_btn", {
          list_package_count:
            addIndex !== "" ? listPackagesAdd.length : listPackages.length,
          package_search_keyword: searchInput,
          result_package_count: packages.data.length,
          user_pk: mainMember?.customerNumber,
        });
      }
      let arr = packages?.data

      if (addIndex !== "") {
          arr = packages.data.map((item: any) => ({
          ...item,
          selected:false,
        }));
        arr.map((val: any, index: any) => {
          customerDetails[addIndex].packages.length > 0 &&
            customerDetails[addIndex].packages.map((val1: any, idx1: any) => {
          
              if (val.id === val1.id) {
                arr[index]["selected"] = true;
              }
            });
        });
        const arrSelected = arr?.filter((v:any)=>v?.selected)
        setListPackagesAdd(arr);
        setSelectedPackages(arrSelected);
      } else {
        arr = packages.data.map((item: any) => ({
          ...item,
          selected:false,
        }));
        arr.map((val: any, index: any) => {
          customerPackages?.length > 0 &&
            customerPackages?.map((val1: any, idx1: any) => {
              if (val.id === val1.id) {
                arr[index]["selected"] = true;
              }
            });
        });
        setListPackages(arr);
        const arrSelected = arr?.filter((v:any)=>v?.selected)
        setSelectedPackages(arrSelected);
      }
      if (filter) {
        //@ts-ignore
        gtag("event", "filter_applied_btn", {
          filtered_package_count: addIndex
            ? listPackagesAdd.length
            : listPackages.length,
          test_parameter_count_selection: `${parameterLt}-${parameterGt}`,
          gender_selection: gender,
          price_range_selection: `${priceLt}-${priceGt}`,
          type_selection: type,
          user_pk: mainMember?.customerNumber,
        });
      }
      if (filter || searchInput) {
        //@ts-ignore
        gtag("event", "trigger_package_list", {
          package_searched_keyword: searchInput,
          package_list_count: addIndex
            ? listPackagesAdd.length
            : listPackages.length,
          filter_applied: true,
        });
      }
      //@ts-ignore
      gtag("event", "pv_package_info", {
        list_package_count: selectedPackages?.length > 0,
        member_type: "additional",
        is_subscription: centreData?.is_subscription,
        payment_method: centreData?.payment_mode,
        user_pk: mainMember?.customerNumber,
        is_user_verified: centreData?.otp_mode === "NV" ? false : true,
      });
    } else if (packages?.length === 0) {
      setListPackagesAdd([]);
      setListPackages([]);
    }
  }, [packages, customerPackages, couponPackage, addIndex]);

  const handleSearch = (val: any) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (partnerKey?.center_key) {
        dispatch(
          getPackagesData({
            url: `?center_3p_config_id=${centreData.id}&search=${val}${
              filter ? `&${filter}` : ""
            }`,
            key: partnerKey?.center_key,
          })
        );
      }
    }, 700);
    setSearchInput(val);
  };
  const handleSelected = (val: any, checked: any, index2: any) => {
    //@ts-ignore
    gtag("event", "checked_package_name", {
      package_name: val.name,
      package_searched_keyword: searchInput,
      filter_applied: filter ? true : false,
      position_number: index2 + 1,
      user_pk: mainMember?.customerNumber,
    });
    let arr = [...selectedPackages];

    if (checked) {
      arr.push(val);
      setSelectedPackages(arr);
    } else {
      arr.length > 0 &&
        arr.map((data: any, index: any) => {
          if (data.id === val.id) {
            arr.splice(index, 1);
            setSelectedPackages(arr);
          }
        });
    }
    if (addIndex !== "") {
      let arr1 = [...listPackagesAdd];
      arr1.map((val: any, index: any) => {
        arr.length > 0 &&
          arr.map((val1: any, idx1: any) => {
            if (val.id === val1.id) {
              arr1[index]["selected"] = true;
            }
          });
      });
      setListPackagesAdd(arr1);
    } else {
      let arr2 = [...listPackages];
      arr2.map((val: any, index: any) => {
        arr.length > 0 &&
          arr.map((val1: any, idx1: any) => {
            if (val.id === val1.id) {
              arr2[index]["selected"] = true;
            }
          });
      });
      setListPackages(arr2);
    }
  };
  const addPackages = () => {
    if (addIndex !== "") {
      updateMember(addIndex, selectedPackages, "packages");
      let arr: any = [];     
      selectedPackages?.length > 0 &&
        selectedPackages?.map((val: any) => {
          arr.push(val.code);
        });
      updateMember(addIndex, arr, "packageCode");
      // updateMember(addIndex, true, "isShow");

      //@ts-ignore
      gtag("event", "package_selection_next_btn", {
        selected_package_name:
          selectedPackages?.length > 0 &&
          selectedPackages?.map((val: any) => val.name),
        member_type: "additional",
        user_pk: mainMember?.customerNumber,
      });
    } else {
      setCustomerPackages(selectedPackages);

      //@ts-ignore
      gtag("event", "package_selection_next_btn", {
        selected_package_name:
          selectedPackages?.length > 0 &&
          selectedPackages?.map((val: any) => val.name),
        member_type: "main",
        user_pk: mainMember?.customerNumber,
      });
    }
    setOpenPackageModal(false);
    // handleSearch("");
    dispatch(
      getPackagesData({
        url: `?center_3p_config_id=${centreData.id}`,
        key: partnerKey?.center_key,
      })
    );
  };
  function handleView(val: any): void {
    setOpenParam(true);
    dispatch(
      getParams({
        url: `?code=${val.code}`,
        key: partnerKey?.center_key,
      })
    );
  }
  useEffect(() => {
    if (params.status === "success" && !loading) {
      setView(params);
    }
  }, [params]);
  const handlePreview = () => {
    if (file?.type === "application/pdf") {
      let link = URL.createObjectURL(file);
      window.open(link, "_blank");
    } else {
      setPreview(URL.createObjectURL(file));
    }
  };
  const submitPrescription = async (val: string) => {
    const formData = new FormData();
    formData.append("name", mainMember?.customerName);
    formData.append("phone", mainMember?.customerNumber);
    formData.append("age", mainMember?.customerAge);
    formData.append(
      "gender",
      mainMember?.customerGender === "male"
        ? "M"
        : mainMember?.customerGender === "female"
        ? "F"
        : "O"
    );
    if (val === "isPrescription") {
      formData.append("prescription", file);
    } else {
      //@ts-ignore
      gtag("event", "no_presc_btn", {
        mobile_number: mainMember?.customerNumber,
        user_pk: mainMember?.customerNumber,
      });
    }

    await dispatch(
      uploadPrescription({ data: formData, key: partnerKey?.center_key })
    );

    //@ts-ignore
    gtag("event", "confim_presc_btn", {
      mobile_number: mainMember?.customerNumber,
      user_pk: mainMember?.customerNumber,
    });
    let data = {
      mobile: mainMember?.customerNumber,
      patient_name: mainMember?.customerName,
      iframe_event: "PRES",
      panel_user: "12363",
      center: centreData?.center,
      lead_type: "b2b",
    };
    if (leadId) {
      await dispatch(
        updateLead({
          id: leadId,
          data: data,
          key: partnerKey?.center_key,
        })
      );
    } else {
      await dispatch(createLead({ data: data, key: partnerKey?.center_key }));
    }
    if (val === "isPrescription") {
      setConfirmType("uploadPrescription");
    } else {
      setConfirmType("requestCallback");
    }
    setKey(1);
  };
  useEffect(() => {
    if (uploadPrescResp?.id && key === 1) {
      setOpenPrescriptionModal(false);
      setOpenPackageModal(false);
      setOpenConfirmedModal(true);
      setKey(0);
    }
  }, [uploadPrescResp, key]);

  

  return (
    <Grid className="content" id="packages">
      <Header isFilter={true} setOpenFilter={setOpenFilter} />
      <Grid spacing={2} className="secondaryContainer">
        <Grid item xs={12}>
          <TextField
            type="text"
            label="Search Packages"
            value={searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {searchInput ? (
                    <ClearIcon
                      style={{ color: "grey" }}
                      onClick={() => handleSearch("")}
                    />
                  ) : (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon color="primary" />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleSearch(e.target.value)}
            variant="outlined"
            color="primary"
            fullWidth
          />
        </Grid>
        <Grid
          style={{
            height:
              selectedPackages?.length === 0 &&
              customerDetails?.length === 0 &&
              centreData?.can_upload_prescription
                ? "calc(100% - 260px)"
                : "calc(100% - 180px)",
            overflow: "auto",
            marginTop: 20,
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Grid item xs={12}>
              {addIndex !== ""
                ? listPackagesAdd &&
                  listPackagesAdd.length > 0 &&
                  listPackagesAdd.map((val: any, index: any) => {

                    
                    return (
                      <Grid
                        style={{
                          width: "100%",
                          padding: "0px 13px 0px 13px",
                        }}
                        key={val.id}
                      >
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ width: "10%", textAlign: "left" }}>
                          <Checkbox
                              color="primary"
                              style={{ padding: 0 }}
                              defaultChecked={val?.selected}
                              disabled={val?.selected && val?.is_ecg && isEcg}
                              onChange={(e) => {
                                handleSelected(val,e.target.checked,index);
                              }}
                            />
                          </div>
                          <div style={{ width: "70%" }}>
                            <p style={{ fontSize: "18px", margin: 0 }}>
                              {val?.name}
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              width: "20%",
                              fontWeight: "bold",
                              display: centreData?.show_package_golive
                                ? "block"
                                : "none",
                            }}
                          >
                            ₹
                            {val?.package_center_prices?.city_offer_price
                              ? val?.package_center_prices?.city_offer_price
                              : val?.package_center_prices?.offer_price}
                            /-
                            <br />
                            <span
                              style={{
                                textDecoration: "line-through",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "gray",
                                margin: "0px 0px 0px -15px",
                              }}
                            >
                              MRP: ₹{val?.package_center_prices?.package_price}
                              /-
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#A2A2A2",
                            margin: "0 20% 0 10%",
                          }}
                        >
                          <span>includes: {val.parameter || 0} parameters</span>
                          <br />
                          {val.description &&
                            val.description.split(",").length > 0 &&
                            String(
                              val.description
                                .split(",")
                                .map((data: any, index: any) => {
                                  return index < 2 ? `${data},` : "";
                                })
                            ).substring(0, 30)}
                          {val?.parameter > 1 ? (
                            <a
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() => handleView(val)}
                            >
                              view more
                            </a>
                          ) : (
                            ""
                          )}
                          {val?.fasting_time ? (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "grey",
                                display: "flex",
                                marginTop: 5,
                              }}
                            >
                              <div style={{ width: "8%" }}>
                                <CheckCircleIcon style={{ fontSize: "15px" }} />
                              </div>

                              <div style={{ width: "92%", fontSize: "12px" }}>
                                {val?.fasting_time}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {val?.tat_time ? (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "grey",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "8%" }}>
                                <CheckCircleIcon style={{ fontSize: "15px" }} />
                              </div>

                              <div style={{ width: "92%", fontSize: "12px" }}>
                                Within {val?.tat_time}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <hr />
                      </Grid>
                    );
                  })
                : listPackages &&
                  listPackages.length > 0 &&
                  listPackages.map((val: any, index: any) => {
                    return (
                      <Grid
                        style={{
                          width: "100%",
                          padding: "0px 13px 0px 13px",
                        }}
                        key={val.id}
                      >
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ width: "10%", textAlign: "left" }}>
                            <Checkbox
                              color="primary"
                              style={{ padding: 0 }}
                              defaultChecked={val?.selected}
                              disabled={val?.selected && isEcg && val?.is_ecg}
                              onChange={(e) => {
                                handleSelected(val, e.target.checked, index);
                              }}
                            />
                          </div>
                          <div style={{ width: "70%" }}>
                            <p style={{ fontSize: "18px", margin: 0 }}>
                              {val?.name}
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              width: "20%",
                              fontWeight: "bold",
                              display: centreData?.show_package_mrp
                                ? "block"
                                : "none",
                            }}
                          >
                            ₹
                            {val?.package_center_prices?.city_offer_price
                              ? val?.package_center_prices?.city_offer_price
                              : val?.package_center_prices?.offer_price}
                            /-
                            <br />
                            <span
                              style={{
                                textDecoration: "line-through",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "gray",
                                margin: "0px 0px 0px -15px",
                                display: centreData?.show_package_golive
                                  ? "block"
                                  : "none",
                              }}
                            >
                              MRP: ₹{val?.package_center_prices?.package_price}
                              /-
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#A2A2A2",
                            margin: "0 20% 0 10%",
                          }}
                        >
                          <span>includes: {val.parameter || 0} parameters</span>
                          <br />
                          {val.description &&
                            val.description.split(",").length > 0 &&
                            String(
                              val.description
                                .split(",")
                                .map((data: any, index: any) => {
                                  return index < 2 ? `${data},` : "";
                                })
                            ).substring(0, 30)}
                          {val?.parameter > 1 ? (
                            <a
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() => handleView(val)}
                            >
                              view more
                            </a>
                          ) : (
                            ""
                          )}
                          {val?.fasting_time ? (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "grey",
                                display: "flex",
                                marginTop: 5,
                              }}
                            >
                              <div style={{ width: "8%" }}>
                                <CheckCircleIcon style={{ fontSize: "15px" }} />
                              </div>

                              <div style={{ width: "92%", fontSize: "12px" }}>
                                {val?.fasting_time}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {val?.tat_time ? (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "grey",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "8%" }}>
                                <CheckCircleIcon style={{ fontSize: "15px" }} />
                              </div>

                              <div style={{ width: "92%", fontSize: "12px" }}>
                                Within {val?.tat_time}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <hr />
                      </Grid>
                    );
                  })}
            </Grid>
          )}
        </Grid>
        {selectedPackages?.length === 0 &&
          customerDetails?.length === 0 &&
          centreData?.can_upload_prescription && (
            <Grid item xs={12} style={{ display: "flex" }}>
              <p style={{ color: "#7B7C7E", width: "73%" }}>
                Can’t find your Package or Test ?
              </p>
              <p
                style={{
                  textAlign: "center",
                  color: "#5866E0",
                  fontWeight: "bolder",
                  width: "27%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenPrescriptionModal(true);
                  //@ts-ignore
                  gtag("event", "package_not_find_btn", {
                    mobile_number: mainMember?.customerNumber,
                    package_searched_keyword: searchInput,
                    filter_applied: filter ? true : false,
                    package_list_count: addIndex
                      ? listPackagesAdd.length
                      : listPackages.length,
                  });
                }}
              >
                CLICK HERE
              </p>
            </Grid>
          )}

        <Grid item xs={12}>
          <StyledButton
            disabled={selectedPackages?.length === 0 ? true : false}
            onClick={() => addPackages()}
          >
            <div style={{ display: "flex", marginTop: "-4px" }}>
              <div style={{ textAlign: "left", width: "90%" }}>
                Package Selected |{" "}
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: "18px",
                  }}
                >
                  {addIndex !== ""
                    ? Number(selectedPackages?.length || 0)
                    : Number(selectedPackages?.length || 0)}
                </span>
              </div>
              <div
                style={{
                  textAlign: "right",
                  fontWeight: "bolder",
                  fontSize: "18px",
                }}
              >
                NEXT
              </div>
            </div>
          </StyledButton>
        </Grid>
      </Grid>
      <Dialog
        disableEscapeKeyDown={true}
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottom"
      >
        <Grid
          container
          spacing={2}
          className="dialogContent"
          style={{ overflow: "auto" }}
        >
          <Grid item xs={12}>
            <Typography component="p">FILTER</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p">Number of Test(s) Parameters</Typography>
          </Grid>
          <Grid container spacing={1} item xs={12}>
            <Grid item>
              <Chip
                variant={parameterLt === 80 ? "filled" : "outlined"}
                color="primary"
                label="less than 80 tests"
                onClick={() => {
                  if (parameterLt === 80) {
                    setParameterGt(0);
                    setParameterLt(10000);
                  } else {
                    setParameterGt(0);
                    setParameterLt(80);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Chip
                variant={parameterGt === 79 ? "filled" : "outlined"}
                color="primary"
                label="between 80 to 100 tests"
                onClick={() => {
                  if (parameterGt === 79) {
                    setParameterGt(0);
                    setParameterLt(10000);
                  } else {
                    setParameterGt(79);
                    setParameterLt(101);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Chip
                variant={parameterGt === 100 ? "filled" : "outlined"}
                color="primary"
                label="more than 100 tests"
                onClick={() => {
                  if (parameterGt === 100) {
                    setParameterGt(0);
                    setParameterLt(10000);
                  } else {
                    setParameterGt(100);
                    setParameterLt(10000);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>

          <Grid item xs={12}>
            <Typography component="p">Gender</Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="female"
                control={
                  <Radio
                    checked={gender === "female" ? true : false}
                    onClick={(e) =>
                      gender === "female" ? setGender("") : setGender("female")
                    }
                  />
                }
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={
                  <Radio
                    checked={gender === "male" ? true : false}
                    onClick={(e) =>
                      gender === "male" ? setGender("") : setGender("male")
                    }
                  />
                }
                label="Male"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>

          {/* <Grid item xs={12}>
            <Typography component="p">Age Range</Typography>
          </Grid>
          <Grid container spacing={1} item xs={12}>
            <Grid item>
              <Chip variant="outlined" color="primary" label="5-18" />
            </Grid>
            <Grid item>
              <Chip variant="outlined" color="primary" label="18-25" />
            </Grid>
            <Grid item>
              <Chip variant="filled" color="primary" label="25-40" />
              <Chip variant="outlined" color="primary" label="40-50" />
              <Chip variant="outlined" color="primary" label="50+" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid> */}

          <Grid item xs={12}>
            <Typography component="p">Price Range</Typography>
          </Grid>
          <Grid container spacing={1} item xs={12}>
            {/* <Grid item xs={12}>
              <Slider
              color="primary"
                getAriaLabel={() => "Temperature range"}
                value={value}
                max={40000}
                min={0}
                step={500}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Grid> */}
            <Grid item>
              <Chip
                variant={priceLt === 500 ? "filled" : "outlined"}
                color="primary"
                label="Below Rs. 500"
                onClick={() => {
                  if (priceLt === 500) {
                    setPriceGt(0);
                    setPriceLt(100000);
                  } else {
                    setPriceGt(0);
                    setPriceLt(500);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Chip
                variant={priceGt === 499 ? "filled" : "outlined"}
                color="primary"
                label="Rs. 500 - Rs. 1000"
                onClick={() => {
                  if (priceGt === 499) {
                    setPriceGt("");
                    setPriceLt("");
                  } else {
                    setPriceGt(499);
                    setPriceLt(1001);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Chip
                variant={priceGt === 1000 ? "filled" : "outlined"}
                color="primary"
                label="Above 1000"
                onClick={() => {
                  if (priceGt === 1000) {
                    setPriceGt("");
                    setPriceLt("");
                  } else {
                    setPriceGt(1000);
                    setPriceLt("");
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>

          <Grid item xs={12}>
            <Typography component="p">Test(s)/Package(s)</Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="test"
                control={
                  <Radio
                    checked={type === "test" ? true : false}
                    onClick={(e) =>
                      type === "test" ? setType("") : setType("test")
                    }
                  />
                }
                label="Test(s)"
              />
              <FormControlLabel
                value="package"
                control={
                  <Radio
                    checked={type === "package" ? true : false}
                    onClick={(e) =>
                      type === "package" ? setType("") : setType("package")
                    }
                  />
                }
                label="Package(s)"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <StyledButton
              onClick={() => {
                setOpenFilter(false);

                setFilter(
                  `recommended_gender=${gender === "other" ? "" : gender}${
                    parameterLt === 10000 && parameterGt === 0
                      ? ""
                      : `&parameter__lte=${parameterLt}&parameter__gte=${parameterGt}`
                  }${
                    priceLt === 100000 && priceGt === 0
                      ? ""
                      : `&price__lte=${priceLt}&price__gte=${priceGt}`
                  }${type ? `&test_or_package=${type}` : ""}`
                );
              }}
            >
              APPLY
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            <StyledButtonLight
              onClick={() => {
                setOpenFilter(false);
                setParameterGt(0);
                setParameterLt(10000);
                setPriceGt(0);
                setPriceLt(100000);
                setType("");
                setFilter(
                  `recommended_gender=${gender === "other" ? "" : gender}`
                );
              }}
            >
              CANCEL
            </StyledButtonLight>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        disableEscapeKeyDown={true}
        open={openParam}
        // onClose={() => setOpenParam(false)}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottom"
      >
        <Grid container spacing={2} className="dialogContent">
          <Grid item xs={11}>
            <Typography
              component="p"
              style={{ fontSize: "16px", fontWeight: "bold", color: "#E06358" }}
            >
              {view && view?.name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Close">
              <CloseIcon onClick={() => setOpenParam(false)} />
            </Tooltip>
          </Grid>
          {loading ? (
            <Grid item xs={12} textAlign={"center"}>
              <Loader />
            </Grid>
          ) : (
            <Grid item xs={12} style={{ overflow: "auto", maxHeight: "80vh" }}>
              {view?.data &&
                view?.data.length > 0 &&
                view.data.map((data: any) => {
                  return (
                    <Grid container>
                      <Grid item xs={10}>
                        {data.name}
                        <span style={{ color: "#7B7C7E" }}>
                          (
                          {`${
                            data?.package_detail?.length > 0
                              ? data.package_detail.length + " tests"
                              : "1" + " test"
                          }`}
                          )
                        </span>
                      </Grid>
                      <Grid item xs={2} textAlign="right">
                        {isSelected === data.name ? (
                          <ExpandLessOutlinedIcon
                            onClick={() => setIsSelected("")}
                          />
                        ) : (
                          <ExpandMoreOutlinedIcon
                            onClick={() => setIsSelected(data.name)}
                          />
                        )}
                      </Grid>
                      {isSelected === data.name ? (
                        <Grid
                          item
                          xs={12}
                          style={{ overflow: "auto", maxHeight: "400px" }}
                        >
                          <ol
                            style={{
                              color: "#7B7C7E",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            {data?.package_detail &&
                              data?.package_detail.length > 0 &&
                              data?.package_detail.map((val: any) => {
                                return <li>{val?.name}</li>;
                              })}
                            {data?.package_detail &&
                              data?.package_detail.length === 0 && (
                                <li>{data.name}</li>
                              )}
                          </ol>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid item xs={12}>
                        {" "}
                        <hr />
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
      </Dialog>
      <Dialog
        disableEscapeKeyDown={true}
        open={openPrescriptionModal}
        keepMounted
        onClose={() => setOpenPrescriptionModal(false)}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottom"
      >
        {file !== "" ? (
          <Grid container spacing={1} className="dialogContent">
            {preview !== "" ? (
              <>
                <Grid item xs={8}>
                  Preview
                </Grid>
                <Grid item xs={4}>
                  <StyledButtonLght onClick={() => setPreview("")}>
                    Back
                  </StyledButtonLght>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <img src={preview} style={{ width: "100%" }} />
                  <span>{file?.name}</span>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  Uploaded Files
                </Grid>
                <Grid item xs={9}>
                  <div
                    style={{
                      background: "#20B0384F",
                      padding: 5,
                      display: "flex",
                      height: "25px",
                    }}
                  >
                    <span style={{ width: "90%" }}>
                      {String(file?.name).substring(0, 22)}...
                    </span>
                    <span
                      style={{
                        width: "10%",
                        color: "#20B038",
                        cursor: "pointer",
                      }}
                      onClick={() => setFile("")}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={3} textAlign={"right"}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handlePreview()}
                    fullWidth
                    style={{ height: 35 }}
                  >
                    Preview
                  </Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <StyledButton
                    onClick={() => submitPrescription("isPrescription")}
                    disabled={loading}
                  >
                    CONFIRM
                  </StyledButton>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <b>Can’t Find your Package / Test</b>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  borderRadius: 6,
                  background: "#eee",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                Don't have prescription ?
                <StyledButtonLight
                  style={{ marginTop: 5, padding: 1 }}
                  onClick={() => submitPrescription("")}
                >
                  REQUEST A CALLBACK
                </StyledButtonLight>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  borderRadius: 6,
                  background: "#E0635820",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                Upload your prescription
                <StyledButton style={{ marginTop: 5 }}>
                  <input
                    style={{ display: "none" }}
                    id={"fileUp"}
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    onChange={(e) => {
                      setFile(
                        e.target.files && e.target.files.length > 0
                          ? e.target.files[0]
                          : ""
                      );
                      //@ts-ignore
                      gtag("event", "upload_presc_btn", {
                        mobile_number: mainMember?.customerNumber,
                        user_pk: mainMember?.customerNumber,
                      });
                    }}
                  />
                  <label
                    htmlFor="fileUp"
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <div style={{ width: "35%", textAlign: "right" }}>
                      <FileUploadOutlinedIcon style={{ marginTop: "-3px" }} />
                    </div>
                    <div style={{ width: "65%", textAlign: "left" }}>
                      UPLOAD
                    </div>
                  </label>
                </StyledButton>
              </div>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </Grid>
  );
};

export default PackagePage;
