import React, { useEffect, useRef, useState } from "react";
import "./index.sass";
import StyledButton from "../../components/Button/StyledButton";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../../components/Header";
import { getSubCenterInfo } from "../../services/actionSlice";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import PatientInfo from "../../components/PatientInfo";
import {
  Box,
  Card,
  InputAdornment,
  Typography,
  TextField,
  Grid,
  MenuItem,
  Button,
  Autocomplete,
  Divider,
} from "@mui/material";
import { AppDispatch } from "../../storage/store";
import Loc from "../../images/loc.svg";
import Distance from "../../images/distance.svg";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  setCustomerName: any;
  customerName: any;
  setCustomerEmail: any;
  customerEmail: any;
  setCustomerAge: any;
  customerAge: any;
  setCustomerGender: any;
  customerGender: any;
  setCustomerNumber: any;
  customerNumber: any;
  setCustomerPackages: any;
  customerPackages: any;
  setFullName: any;
  fullName: any;
  partnerKey: any;
  setOpenSlotModal: any;
  setCentreName: any;
  centreName: any;
  setRemarks: any;
  remarks: any;
  setFile: any;
  file: any;
  setAddIndex: any;
  setAccOpenPackageModal: any;
  latitude: any;
  longitude: any;
  cityName: any;
  setRelation: any;
  relation: any;
  addressInput: any;
  setLocationOpen: any;
  customerDetails: any;
  updateMember: any;
  removeMember: any;
  showMain: any;
  setShowMain: any;
  myself: any;
  removePackage: any;
  isAdditional: any;
  addMember: any;
  handlePackageAcc: any;
  setMyself: any;
  isAddnPkg: any;
  allEmpDetails: any;
}
const RadiologyBooking: React.FC<Props> = ({
  setCustomerName,
  customerName,
  setCustomerEmail,
  customerEmail,
  setCustomerAge,
  customerAge,
  setCustomerGender,
  customerGender,
  setCustomerNumber,
  customerNumber,
  setCustomerPackages,
  customerPackages,
  setFullName,
  fullName,
  partnerKey,
  setOpenSlotModal,
  setCentreName,
  centreName,
  setRemarks,
  remarks,
  setFile,
  file,
  setAddIndex,
  setAccOpenPackageModal,
  latitude,
  longitude,
  cityName,
  setRelation,
  relation,
  addressInput,
  setLocationOpen,
  customerDetails,
  updateMember,
  removeMember,
  showMain,
  setShowMain,
  myself,
  removePackage,
  isAdditional,
  addMember,
  handlePackageAcc,
  setMyself,
  isAddnPkg,
  allEmpDetails,
}) => {
  // const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);
  const subCenterData = useSelector(
    (state: any) => state.webAppSlice.subCenterData
  );
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);

  const company = centreData.custom_user_requirement_for;

  const [mobileValidation, setMobileValidation] = useState<any>(true);
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [expand, setExpand] = useState<any>(true);
  const [ageValidation, setAgeValidation] = useState<any>(true);
  const [genderValidation, setGenderValidation] = useState<any>(true);

  const [preview, setPreview] = useState<any>("");

  const [emailValidation, setEmailValidation] = useState<any>(true);

  //link queries

  const verifyEmail = () => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (customerEmail && !nameRegex.test(customerEmail.toString())) {
      setEmailValidation(false);
    }
  };

  const handlePreview = () => {
    if (file?.type === "application/pdf") {
      let link = URL.createObjectURL(file);
      window.open(link, "_blank");
    } else {
      setPreview(URL.createObjectURL(file));
    }
  };

  const verifyName = () => {
    const nameRegex = /^([a-zA-Z ]){2,30}$/;
    if (customerName && !nameRegex.test(customerName.toString())) {
      setNameValidation(false);
    }
  };

  const verifyAge = () => {
    if (customerAge < 5) {
      setAgeValidation(false);
    }
  };
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E", "."].includes(e.key) && e.preventDefault();

  const verifyMobile = () => {
    const mobileRegEx = /^[6-9]/;

    if (customerNumber && !mobileRegEx.test(customerNumber.toString())) {
      setMobileValidation(false);
    } else {
      setMobileValidation(true);
    }
  };
  useEffect(() => {
    if (latitude && longitude && customerPackages?.length > 0 && cityName) {
      dispatch(
        getSubCenterInfo({
          url: `?imaging=true&customer_latitude=${latitude}&customer_longitude=${longitude}&package=${
            customerPackages && customerPackages?.length > 0
              ? customerPackages.map((val: any) => val.id)
              : ""
          }&multiple_city=${cityName ? cityName : ""}`,
          key: partnerKey?.center_key,
        })
      );
    }
    if (
      customerPackages?.length > 0 &&
      customerPackages[0].recommended_gender !== null &&
      customerPackages[0].recommended_gender !== "both"
    ) {
      setCustomerGender(customerPackages[0].recommended_gender);
    }
  }, [latitude, customerPackages, cityName]);

  return (
    <>
      <Header
        isFilter={false}
        addressInput={addressInput}
        setLocationOpen={setLocationOpen}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid
            container
            spacing={2}
            className={expand ? "dialogContent" : "dialogContentMain"}
          >
            <Grid item xs={10}>
              <Typography component="p">YOUR DETAILS</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expand ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpand(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpand(true)}
                />
              )}
            </Grid>
            {expand ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    className="input"
                    id="outlined-required"
                    label="Full Name"
                    color="primary"
                    disabled={
                      centreData?.allow_upload &&
                      centreData?.frozen_field?.length > 0 &&
                      centreData?.frozen_field.find(
                        (val: any) => val === "name"
                      )
                    }
                    value={fullName}
                    // onBlur={() => setLocalData()}
                    onChange={(e) =>
                      String(e.target.value).length <= 30
                        ? setFullName(
                            e.target.value
                              .replace(/[^a-zA-Z ]/g, "")
                              .trimStart()
                          )
                        : ""
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={String(customerNumber)?.length === 10 ? "0" : "1"}
                    type="number"
                    label="Mobile Number"
                    value={customerNumber}
                    variant="outlined"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    disabled={
                      (centreData?.allow_upload &&
                        centreData?.frozen_field?.length > 0 &&
                        centreData?.frozen_field.find(
                          (val: any) => val === "phone"
                        )) ||
                      fullName === ""
                    }
                    fullWidth
                    required
                    onChange={(e) => setCustomerNumber(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                  />
                  <sub style={{ color: "red" }}>
                    {!mobileValidation
                      ? "Please enter valid mobile number"
                      : ""}
                  </sub>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="email"
                    label="Email Address"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    disabled={
                      (centreData?.allow_upload &&
                        centreData?.frozen_field?.length > 0 &&
                        centreData?.frozen_field.find(
                          (val: any) => val === "email"
                        )) ||
                      centreData?.login_via === "EM"
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p">MEMBER INFORMATION</Typography>
            </Grid>
            {showMain ? (
              <PatientInfo
                name={customerName}
                age={customerAge}
                gender={customerGender}
                packages={customerPackages}
                variant={"outlined"}
                member={1}
                onClick={() => setShowMain(false)}
              />
            ) : (
              <>
                <Grid item xs={12}>
                  Member 1
                </Grid>

                {centreData.allow_relation_field ? (
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      color="primary"
                      className="input"
                      label="Relationship"
                      required
                      key={relation}
                      onChange={(e) => setRelation(e.target.value)}
                      value={relation}
                    >
                      <MenuItem
                        value="self"
                        disabled={
                          customerDetails.length > 0 &&
                          customerDetails.find(
                            (val: any) => val.book_for === "self"
                          ) !== undefined
                        }
                      >
                        Self
                      </MenuItem>
                      <MenuItem
                        value="spouse"
                        disabled={
                          customerDetails.length > 0 &&
                          customerDetails.find(
                            (val: any) => val.book_for === "spouse"
                          ) !== undefined
                        }
                      >
                        Spouse
                      </MenuItem>
                      <MenuItem value="son_in_law">Son</MenuItem>
                      <MenuItem value="daughter_in_law">Daughter</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  {centreData?.has_addtional_member_data &&
                  allEmpDetails.length > 0 ? (
                    <TextField
                      select
                      fullWidth
                      color="primary"
                      className="input"
                      label="Name"
                      required
                      key={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      value={customerName}
                    >
                      {allEmpDetails.length > 0 &&
                        allEmpDetails.map((val: any) => (
                          <MenuItem
                            value={val.customer_name}
                            disabled={
                              customerDetails.length > 0 &&
                              customerDetails.find(
                                (val1: any) =>
                                  val1.customerName === val.customer_name
                              )
                            }
                            onClick={() => {
                              setCustomerAge(val.customer_age);
                              setCustomerGender(val.customer_gender);
                            }}
                          >
                            {val?.customer_name}
                          </MenuItem>
                        ))}
                    </TextField>
                  ) : (
                    <TextField
                      fullWidth
                      color="primary"
                      className="input"
                      id="outlined-required"
                      label="Name"
                      disabled={
                        centreData?.allow_upload &&
                        centreData?.frozen_field?.length > 0 &&
                        centreData?.frozen_field.find(
                          (val: any) => val === "name"
                        )
                      }
                      value={customerName}
                      required
                      onBlur={() => verifyName()}
                      onFocus={() => {
                        setNameValidation(true);
                      }}
                      onChange={(e) =>
                        setCustomerName(String(e.target.value).trimStart())
                      }
                    />
                  )}
                  <sub style={{ color: "red" }}>
                    {!nameValidation
                      ? "Please enter valid name. Remove any special character & number. Name should have 2 to 30 alphabetical characters"
                      : ""}
                  </sub>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    className="input"
                    id="outlined-required"
                    label="Age"
                    disabled={
                      centreData?.allow_upload &&
                      centreData?.frozen_field?.length > 0 &&
                      centreData?.frozen_field.find((val: any) => val === "age")
                    }
                    type="number"
                    value={customerAge}
                    onBlur={() => verifyAge()}
                    onFocus={() => {
                      setAgeValidation(true);
                    }}
                    onKeyDown={(e) => blockInvalidChar(e)}
                    variant="outlined"
                    required
                    onChange={(e) =>
                      Number(e.target.value) < 110
                        ? setCustomerAge(e.target.value)
                        : ""
                    }
                  />
                  <sub style={{ color: "red" }}>
                    {!ageValidation
                      ? "Please enter valid age, age should be 4+ Years"
                      : ""}
                  </sub>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    className="input"
                    id="outlined-required"
                    disabled={
                      (centreData?.allow_upload &&
                        centreData?.frozen_field?.length > 0 &&
                        centreData?.frozen_field.find(
                          (val: any) => val === "gender"
                        )) 
                      //   ||
                      // (customerPackages?.length > 0 &&
                      //   customerPackages[0].recommended_gender !== null &&
                      //   customerPackages[0].recommended_gender !== "both")
                    }
                    label="Gender"
                    key={customerGender}
                    required
                    select
                    value={customerGender}
                    onChange={(e) => setCustomerGender(e.target.value)}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                  <sub style={{ color: "red" }}>
                    {!genderValidation && !customerGender
                      ? "Please select gender"
                      : ""}
                  </sub>
                </Grid>

                {customerPackages && customerPackages?.length > 0 ? (
                  <Grid item xs={12}>
                    {" "}
                    Package Selected
                  </Grid>
                ) : (
                  ""
                )}
                {customerPackages &&
                  customerPackages?.length > 0 &&
                  customerPackages.map((val: any, index: any) => {
                    return (
                      <Grid item xs={12}>
                        <div
                          style={{
                            background: "rgba(209, 64, 72, 0.2)",
                            borderRadius: "5px",
                            color: "#E06358",
                            padding: "5px 0px 1px 14px",
                            display: "flex",
                          }}
                        >
                          <div style={{ width: "90%" }}>{val?.name}</div>
                          <div style={{ width: "10%" }}>
                            <CloseIcon
                              onClick={() => removePackage("", index, "")}
                            />
                          </div>
                        </div>
                        {customerGender &&
                        val.recommended_gender &&
                        val.recommended_gender !== customerGender &&
                        customerGender !== "other" &&
                        val.recommended_gender !== "both" ? (
                          <sub style={{ color: "red" }}>
                            Selected package is not for {customerGender}. Please
                            change package.
                          </sub>
                        ) : (
                          ""
                        )}
                      </Grid>
                    );
                  })}
                <Grid item xs={12}>
                  <StyledButton
                    onClick={() => {
                      setAddIndex("");
                      setAccOpenPackageModal(true);
                      //@ts-ignore
                      gtag("event", "select_package_btn", {
                        gender: customerGender,
                        relationship: relation,
                        user_pk: customerEmail || customerNumber,
                        age: customerAge,
                      });
                    }}
                  >
                    CHANGE PACKAGE
                  </StyledButton>
                </Grid>
              </>
            )}

            {customerDetails &&
              customerDetails.length > 0 &&
              customerDetails.map((val: any, index: any) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "calc(100% - 72px)",
                            marginTop: 15,
                          }}
                        >
                          <Divider />
                        </div>
                        <div
                          style={{
                            width: "72px",
                            textAlign: "right",
                          }}
                        >
                          <Button
                            style={{
                              textTransform: "none",
                              color: "rgba(38, 119, 240, 1)",
                            }}
                            onClick={() => removeMember(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    </Grid>
                    {val.isShow ? (
                      <PatientInfo
                        name={val.customerName}
                        age={val.customerAge}
                        gender={val.customerGender}
                        packages={val.packages}
                        variant={"outlined"}
                        member={index + 2}
                        onClick={() => updateMember(index, false, "isShow")}
                      />
                    ) : (
                      <Grid container style={{ marginTop: "-15px" }}>
                        <Grid item xs={12}>
                          Member {index + 2}
                        </Grid>

                        <Grid item xs={12}>
                          {centreData.allow_relation_field ? (
                            <TextField
                              select
                              fullWidth
                              color="primary"
                              className="input"
                              key={val?.book_for}
                              label="Relationship"
                              required
                              onChange={(e) => {
                                updateMember(
                                  index,
                                  String(e.target.value),
                                  "book_for"
                                );
                                e.target.value === "self" &&
                                  val.customerName === "" &&
                                  updateMember(index, fullName, "customerName");
                              }}
                              value={val?.book_for}
                            >
                              <MenuItem
                                value="self"
                                disabled={
                                  relation === "self" ||
                                  customerDetails.find(
                                    (val: any) => val.book_for === "self"
                                  ) !== undefined
                                }
                              >
                                Self
                              </MenuItem>
                              <MenuItem
                                value="spouse"
                                disabled={
                                  relation === "spouse" ||
                                  customerDetails.find(
                                    (val: any) => val.book_for === "spouse"
                                  ) !== undefined
                                }
                              >
                                Spouse
                              </MenuItem>
                              <MenuItem value="son_in_law">Son</MenuItem>
                              <MenuItem value="daughter_in_law">
                                Daughter
                              </MenuItem>
                              <MenuItem value="other">Other</MenuItem>
                            </TextField>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {centreData?.has_addtional_member_data &&
                          allEmpDetails.length > 0 ? (
                            <TextField
                              select
                              fullWidth
                              color="primary"
                              className="input"
                              label="Name"
                              required
                              key={val.customerName}
                              onChange={(e) =>
                                updateMember(
                                  index,
                                  String(e.target.value).trimStart(),
                                  "customerName"
                                )
                              }
                              value={val.customerName}
                            >
                              {allEmpDetails.length > 0 &&
                                allEmpDetails.map((val: any) => (
                                  <MenuItem
                                    value={val.customer_name}
                                    disabled={
                                      val.customer_name === customerName ||
                                      customerDetails.find(
                                        (val1: any) =>
                                          val1.customerName ===
                                          val.customer_name
                                      )
                                    }
                                    onClick={() => {
                                      updateMember(
                                        index,
                                        val.customer_age,
                                        "customerAge"
                                      );
                                      updateMember(
                                        index,
                                        val.customer_gender,
                                        "customerGender"
                                      );
                                    }}
                                  >
                                    {val?.customer_name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ) : (
                            <TextField
                              fullWidth
                              color="primary"
                              className="input"
                              id="outlined-required"
                              label="Name"
                              value={val.customerName}
                              variant="outlined"
                              required
                              onChange={(e) =>
                                updateMember(
                                  index,
                                  String(e.target.value).trimStart(),
                                  "customerName"
                                )
                              }
                            />
                          )}
                          <sub style={{ color: "red" }}>
                            {/^([a-zA-Z ]){2,30}$/.test(val.customerName) ===
                              false && val.customerName !== ""
                              ? "Please enter valid name. Remove any special character & number. Name should have 2 to 30 alphabetical characters"
                              : ""}
                          </sub>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            color="primary"
                            className="input"
                            id="outlined-required"
                            label="Age"
                            type="number"
                            value={val.customerAge}
                            variant="outlined"
                            onKeyDown={(e) => blockInvalidChar(e)}
                            required
                            onChange={(e) =>
                              Number(e.target.value) < 110
                                ? updateMember(
                                    index,
                                    e.target.value,
                                    "customerAge"
                                  )
                                : ""
                            }
                          />
                          <sub style={{ color: "red" }}>
                            {Number(val?.customerAge) < 5 &&
                            val?.customerAge !== ""
                              ? "Please enter valid age, age should be 4+ Years"
                              : ""}
                          </sub>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            color="primary"
                            className="input"
                            id="outlined-required"
                            label="Gender"
                            select
                            value={val.customerGender}
                            key={val.customerGender}
                            variant="outlined"
                            required
                            onChange={(e) =>
                              updateMember(
                                index,
                                e.target.value,
                                "customerGender"
                              )
                            }
                          >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </TextField>
                          <sub style={{ color: "red" }}>
                            {!genderValidation && val?.customerGender === ""
                              ? "Please select gender"
                              : ""}
                          </sub>
                        </Grid>

                        {val.packages && val.packages.length > 0 ? (
                          <Grid item xs={12}>
                            {" "}
                            Package Selected
                          </Grid>
                        ) : (
                          ""
                        )}
                        {val.packages &&
                          val.packages.length > 0 &&
                          val.packages.map((data: any, index1: any) => {
                            return (
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    background: "rgba(209, 64, 72, 0.2)",
                                    borderRadius: "5px",
                                    color: "#E06358",
                                    padding: "5px 0px 1px 14px",
                                    display: "flex",
                                  }}
                                >
                                  <div style={{ width: "90%" }}>
                                    {data?.name}
                                  </div>
                                  <div style={{ width: "10%" }}></div>
                                </div>
                                {val?.customerGender &&
                                data.recommended_gender &&
                                data.recommended_gender !==
                                  val?.customerGender &&
                                val?.customerGender !== "other" &&
                                data.recommended_gender !== "both" ? (
                                  <sub style={{ color: "red" }}>
                                    Selected package is not for{" "}
                                    {val?.customerGender}. Please change
                                    package.
                                  </sub>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            );
                          })}
                        <Grid item xs={12}>
                          <StyledButton
                            onClick={() => handlePackageAcc(index)}
                            disabled={
                              !val.customerGender ||
                              !val.customerAge ||
                              !val.customerName
                            }
                          >
                            {val.packages && val.packages.length > 0
                              ? "CHANGE "
                              : "SELECT "}
                            PACKAGE
                          </StyledButton>
                        </Grid>
                      </Grid>
                    )}
                  </>
                );
              })}
          </Grid>
        </Card>
        {customerDetails.length < 4
          ? isAdditional && (
              <div style={{ margin: "0 15px" }}>
                <StyledButtonLight
                  onClick={() => addMember()}
                  disabled={
                    customerPackages?.length === 0 ||
                    addressInput === "" ||
                    !mobileValidation ||
                    !nameValidation ||
                    !ageValidation ||
                    customerGender === "" ||
                    (customerDetails.length > 0 && !isAddnPkg) ||
                    !isAdditional
                  }
                >
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: 19,
                      marginRight: 5,
                      marginBottom: "-4px",
                    }}
                  />
                  <span>ADD MORE MEMBER</span>
                </StyledButtonLight>
              </div>
            )
          : ""}
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Autocomplete
                id="sub_center"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCentreName(obj);
                  }
                }}
                options={subCenterData.length > 0 ? subCenterData : []}
                value={centreName}
                style={{ width: "100%" }}
                aria-required
                getOptionLabel={(option: any) =>
                  option?.center?.name
                    ? `Center: ${option?.center?.name}, Address: ${option?.address}`
                    : ""
                }
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      padding: 10,
                      borderBottom: "0.6px solid #F3F3F3",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "10%" }}>
                      <img src={Loc} style={{ marginTop: 3, width: "16px" }} />
                    </div>
                    <div style={{ width: "60%", lineHeight: "12px" }}>
                      {" "}
                      <p style={{ margin: 0 }}>{option?.center?.name}</p>
                      <sub style={{ fontSize: 11 }}>{option?.address}</sub>
                      <br />
                      <sub style={{ color: "green" }}>
                        {option?.additional_detail &&
                        centreData?.id === "3p_G9sNcunPgbRRj8pTYEKD"
                          ? "*" + option?.additional_detail
                          : ""}
                      </sub>
                    </div>

                    <div style={{ width: "30%", textAlign: "right" }}>
                      <img src={Distance} width="25px" />
                      <sup>
                        {option?.distance
                          ? `${Math.trunc(option?.distance)} KM`
                          : ""}
                      </sup>{" "}
                    </div>
                  </li>
                )}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    const searchTerm = "Address:";
                    const indexOfFirst = newInputValue.indexOf(searchTerm);
                    let str = newInputValue.substring(7, indexOfFirst);
                    dispatch(
                      getSubCenterInfo({
                        url: `?imaging=true&name=${newInputValue}&customer_latitude=${latitude}&customer_longitude=${longitude}&package=${
                          customerPackages && customerPackages?.length > 0
                            ? customerPackages.map((val: any) => val.id)
                            : ""
                        }&multiple_city=${cityName ? cityName : ""}`,
                        key: partnerKey?.center_key,
                      })
                    );
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Center"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
                disabled={customerPackages?.length === 0}
              />
            </Grid>
            {centreData?.allow_additional_remarks ? (
              <Grid item xs={12}>
                <TextField
                  label="Additional Remarks (If any)"
                  fullWidth
                  onChange={(e) =>
                    setRemarks(String(e.target.value).trimStart())
                  }
                  value={remarks}
                />
              </Grid>
            ) : (
              ""
            )}
            {centreData?.can_upload_prescription ? (
              file !== "" ? (
                <>
                  {preview !== "" ? (
                    <>
                      <Grid item xs={8}>
                        Preview
                      </Grid>
                      <Grid item xs={4}>
                        <StyledButtonLight onClick={() => setPreview("")}>
                          Back
                        </StyledButtonLight>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <img src={preview} style={{ width: "100%" }} />
                        <span>{file?.name}</span>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        Uploaded Files
                      </Grid>
                      <Grid item xs={9}>
                        <div
                          style={{
                            background: "#20B0384F",
                            padding: 5,
                            display: "flex",
                            height: "25px",
                          }}
                        >
                          <span style={{ width: "90%" }}>
                            {String(file?.name).substring(0, 22)}...
                          </span>
                          <span
                            style={{
                              width: "10%",
                              color: "#20B038",
                              cursor: "pointer",
                            }}
                            onClick={() => setFile("")}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={3} textAlign={"right"}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => handlePreview()}
                          fullWidth
                          style={{ height: 35 }}
                        >
                          Preview
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <Grid item xs={12}>
                  <div
                    style={{
                      borderRadius: 6,
                      background: "#E0635820",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    Upload your prescription(optional)
                    <StyledButton style={{ marginTop: 5 }}>
                      <input
                        style={{ display: "none" }}
                        id={"fileUp"}
                        type="file"
                        accept="image/png, image/jpg, image/jpeg, application/pdf"
                        onChange={(e) =>
                          setFile(
                            e.target.files && e.target.files.length > 0
                              ? e.target.files[0]
                              : ""
                          )
                        }
                      />
                      <label
                        htmlFor="fileUp"
                        style={{ display: "flex", cursor: "pointer" }}
                      >
                        <div style={{ width: "30%", textAlign: "right" }}>
                          <FileUploadOutlinedIcon
                            style={{ marginTop: "-3px" }}
                          />
                        </div>
                        <div style={{ width: "70%", textAlign: "left" }}>
                          UPLOAD PRESCRIPTION
                        </div>
                      </label>
                    </StyledButton>
                  </div>
                </Grid>
              )
            ) : (
              ""
            )}
          </Grid>
        </Card>
        <div style={{ margin: "0 15px 15px" }}>
          <StyledButton
            onClick={() => setOpenSlotModal(true)}
            disabled={
              !mobileValidation ||
              !nameValidation ||
              !ageValidation ||
              customerGender === "" ||
              !customerName ||
              customerPackages?.length === 0 ||
              customerNumber.length !== 10 ||
              !centreName.id ||
              !isAddnPkg
            }
          >
            NEXT
          </StyledButton>
        </div>
        {centreData.patient_additional_text ? (
          <Grid
            item
            xs={12}
            textAlign={"center"}
            style={{ marginBottom: "10px" }}
          >
            <hr style={{ border: "1px solid #ececec" }} />
            <sup>{centreData.patient_additional_text}</sup>
          </Grid>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};
export default RadiologyBooking;
